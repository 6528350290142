import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { ApiResponse } from '../shared/models/apiResponse';
import { Role } from '../shared/constants';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../shared/services/auth.service';
import { RoleService } from '../shared/services/role.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: MenuItem[] = [];
    userRoles: string[] = [];

    constructor(public layoutService: LayoutService,
        private authService: AuthService,
        private roleService: RoleService
    ) {
        this.model = [
            {
                label: 'Unit Admin',
                items: [
                    {
                        label: 'Purchase Units',
                        icon: 'pi pi-credit-card',
                        routerLink: ['/app/units/purchase']
                    },
                    {
                        label: 'Transfer Units',
                        icon: 'pi pi-arrow-right-arrow-left',
                        routerLink: ['/app/units/transfer']
                    },
                    {
                        label: 'Unit Settings (admin)',
                        icon: 'pi pi-cog',
                        routerLink: ['/app/units/settings']
                    }
                ]
            },
            {
                label: 'Testee Admin',
                items: [
                    {
                        label: 'Organizations',
                        icon: 'pi pi-building',
                        routerLink: ['/app/organizations/organization-list']
                    },
                    {
                        label: 'Testees',
                        icon: 'pi pi-users',
                        routerLink: ['/app/testees/testee-list']
                    }
                ]
            },
            {
                label: 'Test Admin',
                items: [
                    {
                        label: 'Available Tests',
                        icon: 'pi pi-file',
                        routerLink: ['/app/available-tests']
                    },
                    {
                        label: 'Assign Tests',
                        icon: 'pi pi-file-edit',
                        routerLink: ['/app/assign-tests']
                    },
                    {
                        label: 'Test Results',
                        icon: 'pi pi-check',
                        routerLink: ['/app/test-results']
                    }
                ]
            },
            {
                label: 'Resources',
                items: [
                    {
                        label: 'Manuals',
                        icon: 'pi pi-book',
                        routerLink: ['/app/manuals']
                    },
                    {
                        label: 'Downloads',
                        icon: 'pi pi-cloud-download',
                        routerLink: ['/app/downloads']
                    }
                ]
            },
            {
                label: 'Client Admin',
                items: [
                    {
                        label: 'Clients (admin)',
                        icon: 'pi pi-users',
                        routerLink: ['/app/client-list']
                    }
                ]
            }
        ];
    }

    ngOnInit() {
        this.getUserRoles();
    }

    getUserRoles() {
        if (!this.roleService.isAdmin()) {
            let unitAdminItems = this.model.find(x => x.label === 'Unit Admin')!.items!;
            unitAdminItems = unitAdminItems.filter(x => x.label !== 'Unit Settings');

            this.model.find(x => x.label === 'Unit Admin')!.items = unitAdminItems;
            this.model = this.model.filter(x => x.label !== 'Client Admin');
        }
    }
}
