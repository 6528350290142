import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from "./auth.service";

@Injectable({ providedIn: 'root' })
export class RoleService {

    constructor(private authService: AuthService) { }

    isAdmin(): boolean {       
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(this.authService.accessToken);

        return decodedToken.role === 'admin';
    }
}