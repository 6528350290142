import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AuthService } from './shared/services/auth.service';
import { AppConfig, LayoutService } from './layout/service/app.layout.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    private currentVersion: string;
    version: string;

    constructor(private primengConfig: PrimeNGConfig,
        private authService: AuthService,
        private http: HttpClient,
        private layoutService: LayoutService) { }

    ngOnInit() {
        this.primengConfig.ripple = true;

        const config: AppConfig = {
            ripple: true, //toggles ripple on and off
            inputStyle: 'outlined', //default style for input elements
            menuMode: 'static', //layout mode of the menu, valid values are "static", "overlay", "slim", "horizontal", "reveal" and "drawer"
            colorScheme: 'light', //color scheme of the template, valid values are "light", "dim" and "dark"
            theme: 'lara-light-purple', //default component theme for PrimeNG
            scale: 16, //size of the body font size to scale the whole application
        };

        this.layoutService.config.set(config);

        this.currentVersion = localStorage.getItem('version')!;

        this.setVersion();

        setInterval(() => this.setVersion(), 30000);

        this.authService.autoLogin();
    }

    setVersion() {
        this.http.get('/assets/config.json').subscribe((data: any) => {
            this.version = data.version;

            if (this.currentVersion !== this.version) {
                localStorage.setItem('version', this.version);
                window.location.reload();
            }

            console.log('Version: ' + this.currentVersion);
        });
    }
}
