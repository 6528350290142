import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { AuthService } from '../shared/services/auth.service';

@Component({
    selector: 'app-topbar',
    styles: [`
        img {
            width: 200px !important;
            position: relative;
            left: 20px;
        }

        @media screen and (min-width: 1024) {
            #menubutton {
                position: relative;
                left: 30px;
            }
        }

        @media screen and (max-width: 768px) {
            #menubutton {
                position: relative;
                left: -150px;
            }
        }

        @media screen and (max-width: 425px) {
            #menubutton {
                position: relative;
                left: 30px;
            }
        }
        `],
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

    userFirstName: string;

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(public layoutService: LayoutService,
        private authService: AuthService
    ) {
        
        this.items = [
            {
                label: 'Profile',
                icon: 'pi pi-user',
                routerLink: ['/app/profile']
            },
            {
                label: 'Practice',
                icon: 'pi pi-fw pi-image',
                routerLink: ['/app/practice']
            },
            {
                label: 'Logout',
                icon: 'pi pi-power-off',
                command: () => {
                    this.authService.logout();
                }
            }
        ];
    }

    ngOnInit() {
        let user = localStorage.getItem('user');
        this.userFirstName = user != null ? (JSON.parse(user)).firstName : '';
    }
}
