<div class="layout-topbar">
    <a routerLink="/app/home">
        <img src="assets/images/MM Home.jpg" alt="logo">
    </a>

    <button id="menubutton" #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars" style="color: #883CAE"></i>
    </button>
    
    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>
    
    <div #topbarmenu class="layout-topbar-menu"
        [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <!-- <button class="p-link layout-topbar-button">
            <i class="pi pi-calendar"></i>
            <span>Calendar</span>
        </button> -->
        <div class="font-bold mt-3" style="color: #883CAE; float: right;">Hi {{userFirstName}}</div>
        <p-menu #menu1 [model]="items" [popup]="true"></p-menu>
        <button style="color: #883CAE"
            class="p-link inline-flex justify-content-center align-items-center h-3rem w-3rem"
            (click)="menu1.toggle($event)"><i class="pi pi-user text-2xl"></i></button>
        <!-- <button class="p-link layout-topbar-button" [routerLink]="'/documentation'">
            <i class="pi pi-cog"></i>
            <span>Settings</span>
        </button> -->
    </div>
</div>