import { catchError } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

        const errorMessage = error?.error?.errorMessage ?? (error.error instanceof Blob ? 'File download error' : error?.error?.text ? "Incorrect serialization - " + error?.error?.text : error.status !== 0 ? error.message : 'API or UI down');

        this.messageService.add({ severity: 'error', summary: 'Error', detail: errorMessage, life: 20000 });
        console.log(error);

        return throwError(() => error)
      })
    );
  }
}

